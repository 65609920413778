import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';

function Team(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [articles, setArticles] = React.useState([]);
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments(
          'Article_Default',
          {
            label: '美麗見證',
          },
          ['-created'],
        );
        let dataList = resp.results.map((it) => {
          if (!it.id && it._id) {
            it.id = it._id.$oid;
          }
          return {...it};
        });
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }
    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'witness',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  let itemWidth =
    dimension.rwd === 'mobile'
      ? (dimension.innerWidth - 40 - 20) / 2
      : (1240 - 100 - 80) / 3;

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">WITNESS</div>
        <div className="title">美麗見證</div>
        <p className="content" style={{marginTop: 20}}>
          {`美麗 是自信的開始，微媞將實現你的美麗嚮往
發佈內容已由當事人同意作為醫療資訊分享使用，治療效果因人而異，實際狀況須由專業醫師審慎評估後而定。`}
        </p>

        <div className="grid" style={{flexDirection: 'row'}}>
          {articles.map((item, idx) => (
            <div
              onClick={() => {
                navigate(`/article?id=${item.id}`);
              }}
              className="grid-item"
              key={idx}
              style={{
                marginBottom: 50,
                width: itemWidth,
              }}>
              <div
                className="grid-item-img "
                style={{
                  backgroundImage: `url(${item.image})`,
                  height: itemWidth,
                }}
              />
              <p className="grid-item-title" style={{marginTop: 15}}>
                {item.title}
              </p>
              <p className="grid-item-outline" style={{marginTop: 15}}>
                {item.outline}
              </p>
            </div>
          ))}
          {[0, 1, 2].map((item) => (
            <div
              className="grid-item"
              style={{
                width: itemWidth,
              }}></div>
          ))}
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }
`;

export default Team;
